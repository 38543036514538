<template lang="pug">
  .st-container
    .matrix-list
      .matrix-list__header
        .matrix-list__title
          h2 {{ 'pages.skill_gap.matrix_lists' | translate }} ({{items.length}})
          p {{ 'pages.skill_gap.here_you_manage_your_skill_gap_matrices' | translate }}
        .matrix-list__header-actions
          .matrix-list__search
            v-input-search(
              :placeholder="$t('pages.skill_gap.search_skill_matrix')"
              type="text"
              v-model="search"
            )
          v-btn.matrix-list__btn(
            v-if="hasPagePermissions()"
            @click="showCreateMatrix = true") {{ 'pages.skill_gap.new_matrix' | translate }}
              icon-plus
      .matrix-list__list
        v-card.matrix-item(
          v-for="item in items"
          :key="item.id")
          v-card-head.matrix-item__head
            v-card-head-label
              .matrix-item__title(
                @click="openMatrix(item)")
                icon-matrix
                h3 {{ item.name }}
            v-card-head-toolbar.matrix-item__toolbar(
              v-if="hasCollaboratorPermissions(item)")
              v-dropdown.matrix-item__menu-btn(
                flat
                right
                width="160"
                width-auto
                auto-close)
                v-btn(
                  icon)
                  icon-more
                template(slot="content")
                  v-card(elevation)
                    v-list(flat)
                      v-list-item.v-list__item(
                        @click="openEditMatrix(item)")
                        icon-pen
                        span {{ 'ui.buttons.edit' | translate }}
                      v-list-item.v-list__item(
                        v-if="hasPagePermissions()"
                        @click="copyMatrix(item)")
                        icon-duplicate
                        span {{ 'ui.buttons.duplicate' | translate }}
                      v-list-item.v-list__item(
                        v-if="hasPagePermissions()"
                        @click="openExport(item)")
                        i.fa.fa-file-export(style={color: '#a0aac3', fontSize: '14px', paddingLeft: '2px', paddingRight: '6px'})
                        span {{ 'pages.administration.export' | translate }}
                      v-list-item.v-list__item.danger(
                        @click="deleteMatrix(item)")
                        icon-delete
                        span {{ 'ui.buttons.delete' | translate }}

          v-card-content.matrix-item__content
            .flex.align-center
              v-baguette
                icon-employees.m-0
                span {{ 'ui.labels.employees' | translate }} ({{ item.employeesCount }})
              span.matrix-item__content-sign x
              v-baguette
                icon-badge.m-0
                span {{ 'ui.labels.skills' | translate }} ({{ item.badgesCount }})

          v-card-actions.matrix-item__actions
            .matrix-item__footer
              .matrix-item__last-updated
                span {{ 'ui.labels.last_updated' | translate }}
                span {{ changeViewData(item.lastUpdatedAt.substring(0, 10)) }}
              v-avatar-group
                v-avatar(
                  v-for="(picture, index) in cutCollaborators(item.collaboratorEmployeeProfilePictures)"
                  :src="picture"
                  :key="index"
                  :size="24")
                v-btn(
                  v-if="hasCollaboratorPermissions(item)"
                  fab
                  @click="openInviteUsers(item)")
                  icon-plus

        v-dialog(
          :width="680"
          :height="576"
          v-model="showInviteUsers")
          invite-users-modal(
            :matrix="currentMatrix"
            @close="showInviteUsers = false")

        v-dialog(
          :width="592"
          :height="636"
          v-model="showCreateMatrix")
          create-matrix-modal(
            @close="showCreateMatrix = false")

        v-dialog(
          :width="592"
          :height="636"
          v-model="showEditMatrix")
          edit-matrix-modal(
            :matrix="currentMatrix"
            @close="showEditMatrix = false")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import InviteUsersModal from './modals/InviteUsersModal'
import CreateMatrixModal from './modals/create/CreateMatrixModal'
import EditMatrixModal from './modals/edit/EditMatrixModal'
import iconPlus from '@/components/svg/icons/IconPlus.vue'
import IconMatrix from '@/components/svg/icons/IconMatrix.vue'
import IconMore from '@/components/svg/icons/IconMore.vue'
import IconEmployees from '@/components/svg/skill-matrix/IconGroupEmployees.vue'
import IconBadge from '@/components/svg/skill-matrix/IconBadge.vue'
import IconDelete from '@/components/svg/icons/IconDelete.vue'
import IconPen from '@/components/svg/icons/IconPen.vue'
import IconDuplicate from '@/components/svg/icons/IconDuplicate.vue'

export default {
  name: 'Index',

  components: {
    InviteUsersModal,
    CreateMatrixModal,
    EditMatrixModal,
    iconPlus,
    IconMatrix,
    IconMore,
    IconEmployees,
    IconBadge,
    IconDelete,
    IconPen,
    IconDuplicate
  },

  data: () => ({
    search: '',
    currentMatrix: null,
    showInviteUsers: false,
    showCreateMatrix: false,
    showEditMatrix: false
  }),

  computed: {
    ...mapGetters('permissions', [
      'currentPermissions'
    ]),

    ...mapGetters('insightsMatrix', ['matrixList']),

    items () {
      return this.search
        ? this.matrixList.filter(item =>
            item.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.matrixList
    }
  },

  mounted () {
    this.loadMatrixList()
  },

  methods: {
    ...mapActions('insightsMatrix', [
      'loadMatrixList',
      'copyMatrix',
      'deleteMatrix'
    ]),

    async openMatrix (item) {
      this.$router.push(`/skill-gap/matrix-list/${item.id}`)
    },

    openExport (item) {
      window.open(process.env.VUE_APP_BASE_URL + '/badges/skill-matrix/' + item.id + '/export', '_blank')
    },

    openInviteUsers (item) {
      this.currentMatrix = item
      this.showInviteUsers = true
    },

    openEditMatrix (item) {
      this.currentMatrix = item
      this.showEditMatrix = true
    },
    changeViewData (data) {
      return data
        .split('-')
        .reverse()
        .join('.')
    },

    cutCollaborators (collaborators = []) {
      return collaborators.slice(0, 8)
    },

    hasPagePermissions () {
      return this.currentPermissions['PAGE_ANALYSIS']
    },

    hasCollaboratorPermissions (item) {
      return item.collaboratorTypeId !== 'VIEW_ONLY'
    }
  }
}
</script>

<style lang="scss">
.matrix-list {
  margin-top: 24px;

  &__header {
    background-color: $color-white;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 24px;
    margin-bottom: 24px;
    &-actions {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
    }
  }

  &__search {
    width: 312px;
    .v-inputserch {
      padding: 12px 16px;
      color: $body-text-2;
      input {
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  &__btn {
    white-space: nowrap;
    svg {
      width: 16px;
      height: 14px;
      margin-left: 8px;
    }
  }

  &__title {
    h2 {
      color: $h2-title;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
      line-height: 16px;
      color: $body-text-2;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }

  .matrix-item {
    &__head {
      padding: 24px 24px 0 24px !important;
    }

    &__content {
      padding: 24px !important;
      &-sign {
        margin: 0 12px;
      }
      .v-baguette {
        padding: 12px 16px;
        width: 152px;
        gap: 8px;
        svg {
          width: 16px;
          height: 16px;
        }
        span {
          font-size: 14px;
          line-height: 14px;
          color: $body-text;
        }
      }
    }

    &__toolbar {
      height: 24px;
      z-index: 2;
    }

    &__actions {
      padding: 0 24px 20px;
    }

    &__title {
      display: flex;
      align-items: center;
      color: $color-dark-green;
      cursor: pointer;
      h3 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
      }

      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    &__menu-btn {
      width: 24px;
      height: 24px;
      transform: translateX(10%);
      button {
        width: 24px;
        height: 24px;
        color: $body-text-2;
      }
      svg {
        height: 14px;
      }

      .v-list__item {
        padding: 8px 16px !important;
        color: $body-text;
        &:hover {
          background-color: $website-color;
        }
        &.danger {
          svg {
            color: inherit;
          }
        }
        svg {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          color: $body-text-2;
        }
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__last-updated {
      display: flex;
      font-size: 10px;
      line-height: 12px;
      span {
        &:first-child {
          color: $body-text-2;
          margin-right: 4px;
        }
        &:last-child {
          color: $body-text;
        }
      }
    }
    .v-avatar-group {
      .v-avatar {
        border: 0 !important;
      }
      .v-btn {
        width: 24px;
        height: 24px;
        z-index: 1;
        border: 0 !important;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
</style>
