<template functional>
<svg :class="[data.class, data.staticClass]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.01" d="M0 0H16V16H0V0Z" fill="white"/>
<path opacity="0.01" d="M0.799927 0.800049H15.1999V15.2H0.799927V0.800049Z" fill="#F8F9FC"/>
<path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M2.40002 2.89966C2.40002 2.62352 2.62388 2.39966 2.90002 2.39966H13.1C13.2326 2.39966 13.3598 2.45234 13.4536 2.5461C13.5473 2.63987 13.6 2.76705 13.6 2.89966V13.0997C13.6 13.3758 13.3762 13.5997 13.1 13.5997H2.90002C2.62388 13.5997 2.40002 13.3758 2.40002 13.0997V2.89966ZM3.40002 3.39966V12.5997H12.6V3.39966H3.40002Z" fill="currentColor"/>
<path d="M11 1.80005C11 1.24776 11.4477 0.800049 12 0.800049H14.2C14.7523 0.800049 15.2 1.24776 15.2 1.80005V4.00005C15.2 4.55233 14.7523 5.00005 14.2 5.00005H12C11.4477 5.00005 11 4.55233 11 4.00005V1.80005Z" fill="currentColor"/>
<path d="M0.799927 1.80005C0.799927 1.24776 1.24764 0.800049 1.79993 0.800049H3.99993C4.55221 0.800049 4.99993 1.24776 4.99993 1.80005V4.00005C4.99993 4.55233 4.55221 5.00005 3.99993 5.00005H1.79993C1.24764 5.00005 0.799927 4.55233 0.799927 4.00005V1.80005Z" fill="currentColor"/>
<path d="M0.799927 12C0.799927 11.4477 1.24764 11 1.79993 11H3.99993C4.55221 11 4.99993 11.4477 4.99993 12V14.2C4.99993 14.7523 4.55221 15.2 3.99993 15.2H1.79993C1.24764 15.2 0.799927 14.7523 0.799927 14.2V12Z" fill="currentColor"/>
<path d="M11 12C11 11.4477 11.4477 11 12 11H14.2C14.7523 11 15.2 11.4477 15.2 12V14.2C15.2 14.7523 14.7523 15.2 14.2 15.2H12C11.4477 15.2 11 14.7523 11 14.2V12Z" fill="currentColor"/>
<path d="M11 6.89966C11 6.34737 11.4477 5.89966 12 5.89966H14.2C14.7523 5.89966 15.2 6.34737 15.2 6.89966V9.09966C15.2 9.65194 14.7523 10.0997 14.2 10.0997H12C11.4477 10.0997 11 9.65194 11 9.09966V6.89966Z" fill="currentColor"/>
<path d="M0.799927 6.89966C0.799927 6.34737 1.24764 5.89966 1.79993 5.89966H3.99993C4.55221 5.89966 4.99993 6.34737 4.99993 6.89966V9.09966C4.99993 9.65194 4.55221 10.0997 3.99993 10.0997H1.79993C1.24764 10.0997 0.799927 9.65194 0.799927 9.09966V6.89966Z" fill="currentColor"/>
<path d="M5.90002 6.89966C5.90002 6.34737 6.34774 5.89966 6.90002 5.89966H9.10002C9.65231 5.89966 10.1 6.34737 10.1 6.89966V9.09966C10.1 9.65194 9.65231 10.0997 9.10002 10.0997H6.90002C6.34774 10.0997 5.90002 9.65194 5.90002 9.09966V6.89966Z" fill="currentColor"/>
<path d="M5.90002 1.80005C5.90002 1.24776 6.34774 0.800049 6.90002 0.800049H9.10002C9.65231 0.800049 10.1 1.24776 10.1 1.80005V4.00005C10.1 4.55233 9.65231 5.00005 9.10002 5.00005H6.90002C6.34774 5.00005 5.90002 4.55233 5.90002 4.00005V1.80005Z" fill="currentColor"/>
<path d="M5.90002 12C5.90002 11.4477 6.34774 11 6.90002 11H9.10002C9.65231 11 10.1 11.4477 10.1 12V14.2C10.1 14.7523 9.65231 15.2 9.10002 15.2H6.90002C6.34774 15.2 5.90002 14.7523 5.90002 14.2V12Z" fill="currentColor"/>
</svg>

</template>
<script>
export default {
  name: 'IconMatrix'
}
</script>
