<template functional>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m11.173 8.826.009-7.152a1.182 1.182 0 1 0-2.365 0l.008 7.152-7.152-.008a1.182 1.182 0 0 0 0 2.365l7.152-.009-.008 7.152a1.177 1.177 0 0 0 1.182 1.183 1.178 1.178 0 0 0 1.183-1.183l-.009-7.152 7.152.009A1.178 1.178 0 0 0 19.508 10a1.176 1.176 0 0 0-1.183-1.182l-7.152.008Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPin'
}
</script>

<style>
</style>
