<template functional>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" :class="[data.staticClass]">
  <path d="M1.62813 0.549993C1.10001 0.690618 0.687507 1.11249 0.546882 1.64687C0.475007 1.91874 0.478132 10.0844 0.546882 10.3594C0.687507 10.8969 1.10626 11.3125 1.64688 11.4531C1.91876 11.525 10.0844 11.5219 10.3594 11.4531C10.8969 11.3125 11.3125 10.8937 11.4531 10.3531C11.525 10.0844 11.525 1.91562 11.4531 1.64687C11.3125 1.10624 10.8969 0.687493 10.3594 0.546868C10.0906 0.478118 1.89063 0.478118 1.62813 0.549993Z" fill="currentColor"/>
  <path d="M12.4937 7.45942C12.4812 10.6719 12.4937 10.4625 12.3125 10.9438C12.2219 11.1875 11.9812 11.55 11.7656 11.7657C11.55 11.9813 11.1875 12.2219 10.9437 12.3125C10.4625 12.4938 10.6719 12.4813 7.4625 12.4938L4.5 12.5032V13.3375C4.5 13.9875 4.50937 14.2157 4.54687 14.3594C4.6875 14.8969 5.10625 15.3125 5.64687 15.4532C5.91562 15.525 14.0844 15.525 14.3531 15.4532C14.8937 15.3125 15.3125 14.8969 15.4531 14.3594C15.4937 14.1969 15.5 13.6469 15.5 10C15.5 5.4063 15.5094 5.6563 15.3094 5.27817C15.1906 5.05942 14.9156 4.78755 14.6937 4.67505C14.3844 4.5188 14.2437 4.50005 13.3375 4.50005H12.5031L12.4937 7.45942Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'IconDuplicate'
}
</script>

<style>
</style>
