<template functional>
<svg :class="[data.class, data.staticClass]" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.01" d="M40 0H0V40H40V0Z" fill="none"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 33.975C9.75 34.6 9.9 35.2 10.15 35.725H3.75C2.775 35.725 2 34.95 2 33.975V24.75C2 22.675 3.675 21 5.75 21H10.1C9.875 21.65 9.75 22.35 9.75 23.075V33.975Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M38.0001 24.7501V34.0001C38.0001 34.9751 37.2251 35.7501 36.2501 35.7501H29.8501C30.1001 35.2001 30.2501 34.6001 30.2501 34.0001V23.0501C30.2501 22.3251 30.1251 21.6251 29.9251 20.9751H34.2501C36.3251 21.0001 38.0001 22.6751 38.0001 24.7501Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M28.25 23.05V33.975C28.25 34.725 27.85 35.4 27.25 35.725C26.975 35.875 26.65 35.975 26.325 35.975H13.675C13.325 35.975 13.025 35.875 12.75 35.725C12.15 35.375 11.75 34.725 11.75 33.975V23.05C11.75 22.325 11.925 21.625 12.225 21.025C12.925 19.675 14.3 18.75 15.875 18.75H24.125C25.7 18.75 27.075 19.675 27.775 21.025C28.075 21.625 28.25 22.325 28.25 23.05Z" fill="currentColor"/>
  <path d="M13.25 15C13.25 12.525 11.225 10.5 8.75 10.5C6.275 10.5 4.25 12.525 4.25 15C4.25 17.475 6.275 19.5 8.75 19.5C11.225 19.5 13.25 17.475 13.25 15Z" fill="currentColor"/>
  <path d="M35.75 15C35.75 12.525 33.725 10.5 31.25 10.5C28.775 10.5 26.75 12.525 26.75 15C26.75 17.475 28.775 19.5 31.25 19.5C33.725 19.5 35.75 17.475 35.75 15Z" fill="currentColor"/>
  <path d="M26.25 10.25C26.25 6.8 23.45 4 20 4C16.55 4 13.75 6.8 13.75 10.25C13.75 13.7 16.55 16.5 20 16.5C23.45 16.5 26.25 13.7 26.25 10.25Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'IconGroupEmployees'
}
</script>

<style scoped></style>
